.home-header {
    background-image: url('/public/images/dailylife2.png');
    background-size: cover;
    background-position: center;
    color: blue;
    padding: 80px 20px;
    text-align: center;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; /* New */
}

/* New: Semi-transparent overlay */
.home-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Adjust transparency as needed */
    z-index: -1;
}

/* Improve text shadow for readability */
.home-header h1 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.mission {
    text-align: center; /* Centering text */
    padding: 40px; /* Increased padding */
    background: #f8f8f8; /* Light background for better readability */
    margin: 20px auto; /* Center align and provide some margin */
    max-width: 80%; /* Max width for better reading experience */
}

.features {
    display: flex;
    flex-wrap: wrap; /* Allow features to wrap on smaller screens */
    justify-content: space-between; /* Spacing out features */
}

.feature {
    flex: 1 1 30%; /* Adjust based on your layout needs */
    margin: 10px;
    padding: 20px;
    background: #fff; /* Background for readability */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

.feature img {
    width: 100%; /* Full width within the feature box */
    margin-bottom: 20px; /* Space below image */
}

.feature div {
    text-align: center; /* Center text inside the feature box */
}

.testimonial, .contact {
    background-color: #000;
    color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center; /* Center align testimonial and contact texts */
}

.testimonial p, .contact p {
    font-style: italic;
    font-size: 1.2em; /* Larger font size for better readability */
}
