/* Header.css */
.main-header {
    background: #333;
    color: #fff;
    padding: 10px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-header ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.main-header li {
    padding: 10px 15px;
}

.main-header a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

.main-header a:hover,
.main-header a:focus {
    color: #cccccc;
}

.dropdown {
    position: relative;
    cursor: pointer;
}

.main-header .dropdown .dropdown-content {
    display: none;
    position: absolute;
    background-color: burlywood;
    min-width: 160px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1050;  /* Ensure this is above other content but below the fixed header */
    border-radius: 4px;
}

.main-header .dropdown:hover .dropdown-content {
    display: block;
}

.main-header .dropdown-content li {
    color: red;
    padding: 12px 16px;
    text-align: left;
    display: block;
}

.main-header .dropdown-content li:hover {
    background-color: #ddd;
}

.logo img {
    height: 70px;
}

@media (max-width: 768px) {
    .main-header {
        flex-direction: column;
    }

    .main-header ul {
        width: 100%;
        justify-content: center;
    }

    .dropdown-content {
        position: relative;
    }
}
