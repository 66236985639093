#case-study.case-study {  /* Combining ID and class for specificity */
    background-position: center;
    color: black;
    padding: 80px 20px;
    text-align: center;
    min-height: 500px;
    background-clip: content-box; /* Contain background within element */
  }
  
  .case-study-title {
    font-size: 28px;
    color: #0056b3; /* Custom color for the title */
    margin-bottom: 20px;
  }
  
  .case-study-content {
    display: flex; /* Added flexbox */
    flex-direction: row; /* Arranged horizontally */
    width: 100%;
    position: relative; /* Establish stacking context for child elements */
    margin: 20px; /* Add margin to create buffer */
  }
  
  .case-study-left, .case-study-right {
    /* Removed flex property */
    padding: 20px;
  }
  
  .case-study-left {
    font-size: 20px; 
    background-color: white;
    padding: 20px;
    flex: 2; /* Take up 2/5ths of the space */
  }
  
  .case-study-right {
    font-size: 16px; 
    padding: 20px 40px;
    flex: 3; /* Take up 3/5ths of the space */
  }
  
  strong {
    color: #000; /* Bold text in black for emphasis */
    font-weight: bold;
  }
  